var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("context-title", {
        attrs: { title: _vm.pageTitle, passedActions: _vm.titleBarActions },
      }),
      _vm.editing
        ? _c("line-item", { staticClass: "height-xs--4" }, [
            _c("span", { staticClass: "edit-bar" }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "row no-gutters" }, [
        _c(
          "div",
          { staticClass: "col-12 tab-bar-container" },
          [
            _c("tab-bar", {
              attrs: {
                items: _vm.navItems,
                params: _vm.navParams,
                fuzzy: _vm.fuzzyTabs,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }