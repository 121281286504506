<template>
  <div>
    <context-title :title="pageTitle" :passedActions="titleBarActions">
        <!-- <div slot="append-buttons" v-if="!hideEnvSelector">
            <env-selector-dropdown v-model="environment"/>
        </div> -->
    </context-title>
    <line-item class="height-xs--4" v-if="editing">
      <span class="edit-bar" />
    </line-item>
    <div class="row no-gutters">
      <div class="col-12 tab-bar-container">
        <tab-bar :items="navItems" :params="navParams" :fuzzy="fuzzyTabs" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import contextTitle from "./context_title.vue";
import lineItem from "./line_item.vue";
import tabBar from "./tab_bar.vue";
import AccessMixin from "mixins/access_helper.js";
import environment_selector from "mixins/environment_selector";
import EnvSelectorDropdown from "components/EnvSelectorDropdown";

export default {
    name: "StoreSettingsHeader",
    mixins: [AccessMixin, environment_selector],
    components: {
        contextTitle,
        lineItem,
        tabBar,
        EnvSelectorDropdown,
    },
    props: {
        pageTitle: {
            type: [Array, String],
            default: () => "Store Settings"
        },
        titleBarActions: {
            type: [Array, Boolean],
            default: false
        },
        editing: Boolean,
        fuzzyTabs: Boolean,
        hideEnvSelector: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
        };
    },
    computed: {
        env() {
            return this.environment;
        },
        navItems() {
            const items = [
                {
                    to: "store-settings",
                    display: "STORE"
                },
                {
                    to: "store-pos-settings",
                    display: "POS"
                }
            ];

            if (this.hasTableNumbers && this.userHasViewPermission("TABLE_NUMBERS")
            ) {
                items.push({
                    to: "store-settings-table-numbers",
                    display: "TABLE NUMBERS"
                });
                items.push({
                    to: "store-settings-sections",
                    display: "SECTIONS"
                });
            }

            items.push({
                to: "store-settings-platforms",
                display: "PLATFORMS"
            });

            if (this.env == 'stage') {
                items.push({
                    to: "store-settings-menu-functions",
                    display: "MENU FUNCTIONS"
                });
            }

            if (this.hasTableTopSettings) {
                items.push({
                    to: "store-settings-tabletop",
                    display: "ORDER AT TABLE"
                });
            }

            if (this.superuser) {
                items.push({
                    to: "store-settings-languages",
                    display: "Languages"
                })
            }
            return items;
        },
        navParams() {
            return this.$route.params;
        },
        hasTableNumbers() {
            if (this.env == "stage") {
                return this.tableNumbersStage != undefined && this.tableNumbersStage.length > 0
            } else if (this.env == "prod") {
                return this.tableNumbersProd != undefined && this.tableNumbersProd.length > 0
            }
        },
        hasTableTopSettings() {
            if (this.env == "stage") {
                return this.tableNumbersStage != undefined && this.tableNumbersStage.length > 0
            } else if (this.env == "prod") {
                return this.tableNumbersProd != undefined && this.tableNumbersProd.length > 0
            }
        },
        ...mapGetters(["integrated", "user", "superuser"]),
        ...mapGetters("SettingsStore", ["tableNumbersStage", "tableNumbersProd", "tabletopSettingsStage", "tabletopSettingsProd"])
    },
    methods: {
        ...mapActions("SettingsStore", ["getTableNumbers", "getTableTopSettings"])
    },
    created() {
        this.getTableNumbers({
            waypointID: this.$route.params.store,
            env: this.env});
        this.getTableTopSettings({
            waypointID: this.$route.params.store,
            env: this.env});
    },
    watch: {
        env(newEnv, oldEnv) {
            this.getTableNumbers({
                waypointID: this.$route.params.store,
                env: newEnv});
        }
    },
};
</script>

<style lang="scss">
@import "~scss/variables";
@import "~scss/mixins";

.status-link {
  cursor: pointer;
}

.refresh-title {
  font-size: 0.8em;
}

.refresh-date {
  display: block;
  padding-top: spacing(xxs);
  color: $grey_light_3;
}

.tab-bar-container {
  padding-left: 0px;
}
</style>
